export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  header_account: "Tài khoản",
  header_title: "GIS Tiên Yên",
  footer_titleName: "UBND HUYỆN TIÊN YÊN, QUẢNG NINH",
  footer_email: "hdndhty@quangninh.gov.vn",
  footer_email_to: "hdndhty@quangninh.gov.vn",
  footer_address: "Phố Đông Tiến 1 - Thị Trấn Tiên Yên - Huyện Tiên Yên - Tỉnh Quảng Ninh",
  footer_hotline: "033.3740.923",
  footer_hotline_tel: "033.3740.923",
  footer_fax: "033.3740.923",
  footer_website: "https://www.quangninh.gov.vn/.../huyentienyen",
  footer_website_to: "https://www.quangninh.gov.vn/donvi/huyentienyen/Trang/Default.aspx", 
  contact_map_center_lat: 21.330835,
  contact_map_center_lng: 107.405146,
  contact_map_marker_lat: 21.330835,
  contact_map_marker_lng: 107.405146,
  contact_map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10066.215608601431!2d107.40116916418022!3d21.330273095816494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314b221471339077%3A0xd12b4080a77e495f!2zSMSQTkQgVUJORCBIdXnhu4duIFRpw6puIFnDqm4!5e0!3m2!1svi!2s!4v1630053721114!5m2!1svi!2s',
};
